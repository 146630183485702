export const statuses = {
  NotInitialized: 'not_initialized',
  OnReview: 'on_review',
  Approved: 'approved',
  Stopped: 'stopped',
  Canceled: 'canceled'
}

export const documentTypes = {
  Main: 'main',
  Accompanying: 'accompanying'
}

export const translateDocumentType = x => ({
  [documentTypes.Main]: 'Документ на согласование',
  [documentTypes.Accompanying]: 'Сопроводительный документ'
})[x]

export const getEditableFields = ({ status } = {}) => ({
  [statuses.NotInitialized]: {
    'approval_end': {
      required: true
    },
    'approvers': {
      required: true
    }
  },
  [statuses.OnReview]: {
    'approvers': {
      required: true
    }
  }
})[status] || {}
